$(document).ready(function() {
  const isInfluencersPage = window.location.pathname == '/influencerreviews';
  if(isInfluencersPage){
    getUserfriend('list-frends');
    getSocialUserlistInfluencers('#list-influencers')
    findReviews('friends');
  }

  $(".select2-friend").select2({
    placeholder: "Select friends",
    allowClear: true,
  });

  $(".select2-influencer").select2({
    placeholder: "Select influencers",
    allowClear: true
  })
 
  $('.social-media-minstars, .socialfilter-restaurantstarrating, .socialfilter-menustarrating').rateYo({
    rating: 2,
    starWidth: "30px",
    spacing: "6px", 
    ratedFill: "green",
    normalFill: "#bdbcbc",
    starSvg: __platerate_icons.my_heart_plate,
  });
  const tabsocialmediafriends = $('input[name= "tabsocialmediafriends"]');
  const tabsmediainfluencers = $('input[name= "tabsocialmediainfluencers"]');


  tabsocialmediafriends.change(function(){
    if(tabsocialmediafriends.is(':checked')) {
      $('.drop-down-friendsocial a').removeClass('disabled');
      $('#list-social-frends').show();
    } else {
      savefriends = $('#friendsocial').val();
      $('.drop-down-friendsocial a').addClass('disabled');
      $('#list-social-frends').hide();
    }
  })
  tabsmediainfluencers.change(function(){
    if(tabsmediainfluencers.is(':checked')) {
      $('.drop-down-influencerssocial a').removeClass('disabled');
      $('#list-social-influencers').show();
    } else {
      saveinfluencers = $('#influencerssocial').val();
      $('.drop-down-influencerssocial a').addClass('disabled');
      $('#list-social-influencers').hide();
    }
  })
   $('.drop-down-friendsocial').on('change', 'input[type="checkbox"]', function(){
    const valOption = $.trim($(this).val())
    selectDropdown('friendsocial', valOption);
    activeOption('friendsocial');
  })

  $('.drop-down-influencerssocial').on('change', 'input[type="checkbox"]', function(){
    const valOption = $.trim($(this).val())
    selectDropdown('influencerssocial', valOption);
    activeOption('influencerssocial');
  })

   $('#list-social-frends').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-social-frends");
    console.log("ul====",ul);
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })


  $('#list-social-influencers').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-social-influencers");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })
/*--------------end SOCIAL MEDIA FEED-----------------*/

//Search friends and influencers reviews
  const tabFriend = $('input[name= "tabfriends"]');
  const tabInfluencers = $('input[name= "tabinfluencers"]');


  tabFriend.change(function(){
    if(tabFriend.is(':checked')) {
      $('.drop-down-friends a').removeClass('disabled');
      $('#list-frends').show();
      $(this).attr("checked","checked").prop("checked",true)
    } else {
      savefriends = $('#friends').val();
      $('.drop-down-friends a').addClass('disabled');
      $('#list-frends').hide();
      $(tabFriend).removeAttr("checked")
    }
  })

  tabInfluencers.change(function(){
    if(tabInfluencers.is(':checked')) {
      $('.drop-down-influencers a').removeClass('disabled');
      $('#list-influencers').show();
      $(this).attr("checked","checked").prop("checked",true)
    } else {
      saveinfluencers = $('#influencers').val();
      $('.drop-down-influencers a').addClass('disabled');
      $('#list-influencers').hide();
      $(tabInfluencers).removeAttr("checked")
    }
  })
  
  
  $('#select-friends').on('change', function(){
    let valselect  = $(this).val();
    if(valselect === 'select') {
      $('#list-frends').find('input[type="checkbox"]').prop('checked', true);
    } else {
      $('#list-frends').find('input[type="checkbox"]').prop('checked', false);
    }
  })

  $('#select-influencers').on('change', function(){
    let valselect  = $(this).val();
    if(valselect === 'select') {
      $('#list-influencers').find('input[type="checkbox"]').prop('checked', true);
    } else {
      $('#list-influencers').find('input[type="checkbox"]').prop('checked', false);
    }
  })
  
  $("#public-ratings").on('input', '#radius-location', function(e){
    let radiusVal = Number($(this).val()||0);
    commonSetRadius(radiusVal, '#reviews-radius-value-text','#public-ratings');
  });
  $("#public-ratings-friends").on('input', '#radius-location', function(e){
    let radiusVal = Number($(this).val()||0);
    commonSetRadius(radiusVal, '#reviews-radius-value-text','#public-ratings-friends');
  });
  function commonSetRadius(radius, spanTextId, parentId){
    
    if (Number(radius) < 25) {
        radius = setRadius(radius, 25, 0.1, 0.5)
    } else if (Number(radius) < 50) {
        radius = setRadius(radius - 25, 25, 0.5, 5)
    } else {
        radius = setRadius(radius - 50, 50, 5, 100)
    }
    $(parentId).find(spanTextId).text(radius);
  }
  $('#list-frends').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-frends");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })


  $('#list-influencers').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-influencers");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })

  // $("#public-ratings-friends").on('input', '#radius-location', function(e){
    // $("#public-ratings-friends").find('#reviews-radius-value-text').text($(this).val());
  // });

 $('#radius-location, #restaurant-radius, #radius-location-socialMediaFeed').on('input',function(e){
    let elemId = $(this).attr('id');
    let radius = Number($(this).val()||0);       
    if (Number(radius) < 25) {
        radius = setRadius(radius, 25, 0.1, 0.5)
    } else if (Number(radius) < 50) {
        radius = setRadius(radius - 25, 25, 0.5, 5)
    } else {
        radius = setRadius(radius - 50, 50, 5, 100)
    }
    if(elemId === 'radius-location'){
        $('#reviews-radius-value-text').text(radius);
    }else if(elemId === 'restaurant-radius'){
        $('#restaurant-radius-value').text(radius);
    }else if(elemId === 'radius-location-socialMediaFeed'){
        $('#socalMediaFeeds-radius-value-text').text(radius);
        $('#radius-search-for-socialMediaFeed').val(radius);
    }
    $('#radius-search-for-socialMediaFeed').val(radius);
  });

  $('#list-frends').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-frends");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })


  $('#list-influencers').on('input', '.filter', function(){
    var input, filter, ul, li, name, i;
    input = $(this).val();
    filter = input.toUpperCase();
    ul = document.getElementById("list-influencers");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        name = $(li[i]).find('input[name="option"]').data('name');
        if(name) {
          if (name.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
        }
    }
  })

  const setRadius = function (prosses, prossesMax, min, max) {
    let length = max - min;
    let rate = prosses / prossesMax
    let radius = min + (rate * length);
    return radius.toFixed(1);
  }

  /*var geocoder;
  attach('https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAP_API_KEY + '&libraries=places').then(function (res) {
    geocoder = new google.maps.Geocoder();
    let dAddress = document.getElementById('reviews-location');
    let dAddress2 = document.getElementById('reviews-restaurant-location');



  });*/

  $(document).ready(function(){
    function getLocationHomeAddressFun(autocomplete){
      return __get_location().then(position => {
        if(position){
          var geolocation = {
              lat: position.lat,
              lng: position.long
          };
          var circle = new google.maps.Circle({
              center: geolocation,
              radius: 1000
          });
          autocomplete.setBounds(circle.getBounds());
        }
      })
    }
  
    var __get_location = ()=>{
      let location = {
          lat: null,
          long: null
      }
      return $.ajax('/geoip/address')
      .then( coords => {
          if (coords && coords.ll.length) {
              location.lat = res.ll[0]
              location.long = res.ll[1]
              return location
          }
      })
      .catch(()=>{
        let ipapi_key = $("#ipapiSecret").val().toString()
        return $.ajax('https://ipapi.co/json/?key=' + ipapi_key)
        .then(res=>{
            location.lat = res.latitude
            location.long = res.longitude
            return location
        })
        .catch(()=>{ return location })
      })
    }
  
  
    function initializeAutocompleteHomeAddress(addressElem){
      if(addressElem) {
        let autocomplete = new google.maps.places.Autocomplete((addressElem), {types: ['geocode']});
        autocomplete.addListener('place_changed', fillInHomeAddress);
        getLocationHomeAddressFun(autocomplete);
        if(!check_iOSapp() && !check_androidApp()){
          var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (is_safari){
          var googlelocationValue=Cookies.get('locationPermission');
          if(googlelocationValue!='granted'){
              getLocationHomeAddressFun(autocomplete);
          }
          else{
              var savedLat = Cookies.get('posLat');
              var savedLon = Cookies.get('posLon');
              var position = {coords:{latitude: savedLat, longitude: savedLon}, fromIp:false};
              var geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
              };
              var circle = new google.maps.Circle({
                  center: geolocation,
                  radius: position.coords.accuracy
              });
              autocomplete.setBounds(circle.getBounds());
          }
          }else{
              getLocationHomeAddressFun(autocomplete);
          }
        } else {
          var geolocation = {
              lat: $("#nativeAppLatitude").val(),
              lng: $("#nativeAppLongitude").val()
          };
          var circle = new google.maps.Circle({
              center: geolocation
          });
          autocomplete.setBounds(circle.getBounds());
        }
      }
    }
    function fillInHomeAddress(e) {
      //var place = autocomplete.getPlace();
    }
  
    $('#reviews-location , #reviews-restaurant-location').focus(function() {
     /* let autocomplete = new google.maps.places.Autocomplete($(this), {types: ['geocode']});
      getLocationHomeAddressFun(autocomplete);*/
  
      let dAddress = document.getElementById('reviews-location');
      let dAddress2 = document.getElementById('reviews-restaurant-location');
  
      initializeAutocompleteHomeAddress(dAddress);
      initializeAutocompleteHomeAddress(dAddress2);
    });
  })

  $('#restaurantinfo').select2({
    allowClear: true,
    placeholder: "",
    maximumSelectionSize: 1,
    tags: true,
    ajax: {
      url: '/user/restaurantrating',
      data: function (params) {
        var query = {
          text: params.term
        }
        return query;
      },
      processResults: function (data) {
          return {
              results: $.map(data, function (item) {
                  return {
                      text: item.venue.name,
                      id: item.venue.name,
                  }
              })
          };
      }
    }
  });

});
$('#restaurantinfo').on('select2:open', function (e) {
   let text = $("#restaurantinfo").val()
   $(".select2-search__field").val(text);
});

  $(document).on("click", function(event){
    if($('#list-influencers').is(':visible')){
      $('.box-drop-down').hide();
    }
    if($('#list-frends').is(':visible')){
      $('.box-drop-down').hide();
    }
    if($('#list-social-influencers').is(':visible')){
      $('.box-drop-down').hide();
    }
    if($('#list-social-frends').is(':visible')){
      $('.box-drop-down').hide();
    }
  });

	$(".box-drop-down").click(function(e) {
		e.stopPropagation(); 				 // Dropdown will not hide when select checkbox..
	});

  $('.drop-down-friends').on('change', 'input[type="checkbox"]', function(){
    const valOption = $.trim($(this).val())
    selectDropdown('friends', valOption);
    activeOption('friends');
  })

  $('.drop-down-influencers').on('change', 'input[type="checkbox"]', function(){
    const valOption = $.trim($(this).val())
    selectDropdown('influencers', valOption);
    activeOption('influencers');
  })

  $('#headingSocialFriendsReviews').on('click',function(){
    $(this).unbind('click') //so that below gets called only on 1st click
  //get influencers on load
    getUserfriend('list-social-frends');
    getSocialUserlistInfluencers('#list-social-influencers');
  })
  $(document).ready(function(){
    getUserfriend('list-frends')
    getSocialUserlistInfluencers('#list-influencers')
  })
  function queryRivewsMenuItem(pageNumber){
    let savedLat = __user_location.latitude
    let savedLon = __user_location.longitude
    if(!savedLat || !savedLon) {
      savedLat = 40.730610
      savedLon = -73.935242
    }
    let enableSubmit = true;
    $('span.text-danger').text('');
    const condition = {
      address: $('#reviews-location').val().trim(),
      searchradius: $("#reviews-radius-value-text").text().trim(),
      queryString: $("#menuinfo").val().trim(),
      userId: $("#myuser-id").val().trim(),
      minStars: $('#ratebox-search').val(),
      sort: $('#sort-reviews').val(),
      lat: savedLat,
      lng: savedLon,
      pageNumber: pageNumber,
      nPerPage: perPage,
      publicReview: true
    };

    let tbf = $('input[name= "tabfriends"]');
    let tbi = $('input[name= "tabinfluencers"]');
    var friends = []
    var influencers = []
    if((tbf.length && tbf.is(':checked')) && (tbi.length && tbi.is(':checked'))) {
      condition.friends = []
           $.when(
              $.ajax({url: '/userfriends/friends', method: 'GET' }),
              $.ajax({ url: '/influencerController/getInfluencersList/', method: 'GET' })
            )
            .done(function(resfriends, resinfluencers){
              
              if (resfriends && resfriends[0] && resfriends[0].friends) {
                condition.friends = []
                resfriends[0].friends.forEach(friend => {
                  condition.friends.push(friend._id)
                })
                friends = condition.friends
              }
              if (resinfluencers && resinfluencers[0].data) {
                condition.influencers = []
                resinfluencers[0].data.forEach(influencer => {
                  condition.influencers.push(influencer._id)
                })
                influencers = condition.influencers
              }
              searchRivewsMenuItem(condition)
                .then(function (res) {
                  REVIEWS = res
                  reviewsPage(res, pageNumber);
                });
            })

             saveUserSearch(condition, 'friends');
             saveUserSearch(condition, 'influencers');
    }
    
    if ((tbf.length && tbf.is(':checked') && (!tbi.is(':checked')))) {
      condition.friends = []
      parents.find('input[name="option"].friends').each(function() {
        let frendId = $.trim($(this).val());
        if($(this).is(':checked') && frendId) condition.friends.push(frendId);
      });
      searchRivewsMenuItem(condition)
      .then(function (res) {
        REVIEWS = res
        reviewsPage(res, pageNumber);
      });
      saveUserSearch(condition, 'friends');
    }
    if((tbi.length && tbi.is(':checked')) && (!tbf.is(':checked')) ) {
      condition.influencers = []
      parents.find('input[name="option"].influencers').each(function() {
        let influencerId = $.trim($(this).val());
        if($(this).is(':checked') && influencerId) condition.influencers.push(influencerId);
      });
      searchRivewsMenuItem(condition)
      .then(function (res) {
        REVIEWS = res
        reviewsPage(res, pageNumber);
      });
      saveUserSearch(condition, 'influencers');
    }
    if($('#my-review').is(':checked')) condition.myreview = true;
    if(condition.minStars) {
      condition.minStars = Number(condition.minStars) > 5 ? 5 : Number(condition.minStars)
    }
    if(!enableSubmit) {
      return;
    }

    parents.find('#result-search-reviews').empty();
    $('#resultRatedItems > nav').empty();
    parents.find('#search-reviews-text').css('display', 'none');
    parents.find('#search-reviews-text').text('');
    parents.find('#search-review-loading').fadeIn();
    searchRivewsMenuItem(condition)
    .then(function(res){
      REVIEWS = res
      reviewsPage(res, pageNumber);
    });
  };
function loadRate(itemRating) {
  if(itemRating > 5) itemRating = 5
  let ratehtml = document.createElement('div');
  ratehtml.innerHTML = "<div class='rate-heart-plate' data-id=" + rateid + " data-rating=" + itemRating + "></div>";
  $(ratehtml.children).rateYo({
    rating: itemRating,
    starWidth: "20px",
    spacing: "3px",
    ratedFill: "green",
    normalFill: "#bdbcbc",
    starSvg: __platerate_icons.heart_plate
  });
  rateid = rateid + 1;
  return ratehtml.innerHTML;
}
  function reviewsPage (reviews, pageNumber) {
    parents.find('#search-review-loading').fadeOut('slow', function() {
      let str = '';
      let totalMenus = 1;
      let pageStart = (pageNumber - 1) * perPage;
      let pageEnd = pageStart + perPage;
      const fbAppId = $("#fbAppId").val();
      if (reviews && reviews.data && reviews.data.length){
        const menus = reviews.data.slice(pageStart, pageEnd);
        totalMenus = reviews.data.length;
        parents.find('#search-reviews-text').css('display', 'block');
        parents.find('#search-reviews-text').text(`Found ${totalMenus} result(s)`);
        if(menus.length) {
          menus.forEach(function(item){
            if (item.rating) {
              const review = item.rating;
              let ratingImage = '/images/tempfoodnotext.png';
              if (review.imageUrls) {
                ratingImage = review.imageUrls[0] ? review.imageUrls[0].url || review.imageUrls[0] : '/images/tempfoodnotext.png';
              }
               let heartHtml=loadRate(review.starRating);
              str = str + `<hr/><div class="row yesMarginY">
                <img src="${ratingImage}" class="img-responsive rating-img"/>
                <div class="col-xs-12 col-md-6">
                  <div class="center-flex-block">
                    <h5 class="media-heading" style="margin-top:15px">
                      <a href="/restaurant/${item.venueSlug? item.venueSlug : review.venueId}?menuItem=${review.menuItemSlug ? review.menuItemSlug : review.menuItemId}" class="orange rated-item-name" >${review.menuItemName}</a>
                      <span class="black rated-item-price" id="price">${(review.price && !isNaN(review.price)) ? "$" + review.price : '' }</span>
                    </h5>
                  </div>
                  <div class="center-flex-block">
                    <div class="inline-flex-block">
                      ${heartHtml}
                    </div>
                  </div>
                  <div class="center-flex-block">
                    <p style="max-width: 350px">${review.description || ''}</p>
                  </div>
                  <div class="center-flex-block">
                      <span class="grey">at </span>
                      <a class="green rated-venue-name" href="/restaurant/${item.venueSlug ? item.venueSlug: review.venueId}">${item.name}</a>
                  </div>
				   <div class="center-flex-block">
                      <span class="grey">by </span>
                      <a class="green rated-venue-name" href="/Publicprofile/${review.userId}">${review.userName}</a>
                  </div>
                  <div class="center-flex-block venue-distance">
                      <label><span class="green">${item.distance || 0} miles</span> away</label>
                  </div>
                  <div class="center-flex-block">
                      <p><span class="grey">on</span>&nbsp;${review.dateUpdated ? new Date(review.dateUpdated).toString().slice(0, 15) : ''}</p>
                  </div>
                  <br/>
                </div>
              </div>`
            }
          })
        }
      } else if(reviews.message) {
        str = `<p class="text-danger text-center" id="text-search-reviews">${res.message}</p>`
      } else {
        parents.find('#search-reviews-text').text("No available item");
      }
      parents.find('#result-search-reviews').html(str);
      var id="resultRatedItems";
      setPagination(Number(pageNumber), Number(totalMenus),id);
    });
  }

  function searchRivewsMenuItem(condition){
      return $.ajax({
          url: '/rating/searchmenuitemrating',
          method: 'POST',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({searchParams: condition}),
          dataType: 'json',
      })
  }

  const scroll = (id) => {
    if($(id).length) $('html, body').animate({ scrollTop: $(id).offset().top}, 0);
  }

  function validate (data, option) {
    if(data[option] && !data[option].length) {
      $('#alert-'+option).text('This field is required');
      $('html, body').animate({ scrollTop: $('#'+option).offset().top - 5 }, 'slow');
      return false;
    }
    return true
  }

  function saveUserSearch(data, option) {
    const userId = $.trim($("#myuser-id").val());
    if( userId && data[option] && data[option].length && typeof (Storage) !== "undefined") {
      localStorage[userId+option] = data[option];
    }
  }

  function selectedFriend (friendId, option) {
    if(typeof (Storage) !== "undefined") {
      const userId = $.trim($("#myuser-id").val());
      const friendsId = localStorage[userId+option];
      if(friendsId && friendsId.length) return friendsId.includes(friendId) ? 'checked' : '';
    }
    return 'checked';
  }

  function autoSearchReviews(clickedtype) {
    if(typeof (Storage) !== "undefined") {
      const userId = $.trim($("#myuser-id").val());
      const reviewUserId = $.trim($("#user-id").val());
      let friendsId = [];
      let influencersId = [];
      if(userId && clickedtype=='friends') {
        friendsId = localStorage[userId+'friends'];
        influencersId = localStorage[userId+'influencers'];
        if((friendsId && friendsId.length) || (influencersId && influencersId.length) || reviewUserId) 
        findReviews('friends');
      }
    }
  }

  function getUserfriend(id) {
    var  listFrends = $('#'+id);
    console.log("listFrends===",listFrends);
    let optionDefault = `<li style="padding: 10px">
                          <div class="inline-input-icon" style="padding: 0;">
                            <span class="fa fa-search green detect-delivery-location" style="top: 5px;  font-size: 16px;"></span>
                            <input name="filter" type="text" placeholder="Search for names..."  class="pl-4 form-control filter">
                          </div>
                        </li>
                         <li class="list-option">
                           <div class="form-group">
                             <label class="custom-control custom-checkbox preference-items defautl">
                             <input type="checkbox" value="select" name="select" class="custom-control-input mt-2" id="friends-select">
                             <label class="custom-control-label" for="friends-select">Select all</label>
                             </label>
                           </div>
                         </li>
                         <li class="list-option">
                           <div class="form-group">
                             <label class="custom-control custom-checkbox preference-items defautl">
                             <input type="checkbox" value="de-select" name="de-select" class="custom-control-input mt-1" id="friends-deselect">
                             <label class="custom-control-label" for="friends-deselect">De-select all</label>
                             </label>
                           </div>
                          </li>`
    listFrends.html(optionDefault);
    $.ajax({
      url: '/userfriends/friends',
      method: 'GET',
      contentType: 'application/json; charset=utf-8',
    }).then(function(res){
      if(res.friends && res.friends.length) {

        let currentUserId = res.currentUserId;
        listFrends.append('<li class="divider"></li>');

        const friends = res.friends.map((element) => {
          let friend = element.user;
          if(element.user._id == currentUserId){
              friend = element.invitedByUser;
          }
          let friendName='';
          if(friend.profile && friend.profile.contactInfo){
             friendName= getDisplayName(friend.profile.contactInfo.firstName , friend.profile.contactInfo.lastName);
          
          }
          if(friendName && friendName.trim()) friendName = friendName.trim();
          else friendName = friend.local.email;
          friend.friendName = friendName;
          return friend;
        })

        friends.sort(function(a, b) {
          if ( a.friendName < b.friendName ) return -1;
          if ( a.friendName > b.friendName ) return 1;
          return 0;
        });

        friends.forEach(function(element){
          let selected = selectedFriend(element._id, 'friends');
          let option = `<li class="list-option">
                           <div class="form-group">
                             <label class="custom-control custom-checkbox preference-items">
                             <input type="checkbox" value="${element._id}" name="option" class="friends custom-control-input" data-name="${element.friendName}" id="friends-rev-${element._id}" ${selected}>
                             <label class="custom-control-label" for="friends-rev-${element._id}">${element.friendName}</label>
                             </label>
                           </div></li>`
          listFrends.append(option);
        });
      }
      if(id=="list-frends"){
        
        selectDropdown('friends','select');
      }
      else{
       
        selectDropdown('friendsocial','select');
      }
      activeOption('friends');
    });
  }

  function getSocialUserlistInfluencers(element) {
    var listInfluencers = $(element);
    let optionDefault = `<li style="padding: 10px">
                          <div class="inline-input-icon" style="padding: 0;">
                            <span class="fa fa-search green detect-delivery-location" style="top: 8px;  font-size: 16px;"></span>
                            <input name="filter" type="text" placeholder="Search for names..."  class="form-control filter">
                          </div>
                        </li>
                        <li class="list-option">
                          <label class="col-xs-12 preference-items defautl">
                            <input name="select" type="checkbox" checked value="select">Select all
                            <span class="icon-checkbox"></span>
                          </label>
                        </li>
                        <li class="list-option">
                          <label class="col-xs-12 preference-items defautl">
                            <input name="de-select" type="checkbox" value="de-select">De-select all
                            <span class="icon-checkbox"></span>
                          </label>
                        </li>`
    listInfluencers.html(optionDefault);
    $.ajax({
      url: '/influencerController/getInfluencersList/',
      method: 'GET',
      contentType: 'application/json; charset=utf-8',
    }).then(function(res){
      if(res && res.data) {
        listInfluencers.append('<li class="divider"></li>');
        const influencers = res.data.map((influencer) => {
          let influencerName = '';
          if(influencer.profile && influencer.profile.contactInfo){
             influencerName= getDisplayName(influencer.profile.contactInfo.firstName , influencer.profile.contactInfo.lastName);
          
          }

          if(influencerName && influencerName.trim()) influencerName = influencerName.trim();
          else influencerName = influencer.local.email;
          influencer.influencerName = influencerName;
          return influencer;
        })

        influencers.sort(function(a, b) {
          if ( a.influencerName < b.influencerName ) return -1;
          if ( a.influencerName > b.influencerName ) return 1;
          return 0;
        });

        influencers.forEach(function(element){
          let option = `<li class="list-option">
                           <div class="form-group">
                             <label class="custom-control custom-checkbox preference-items">
                             <input type="checkbox" value="${element._id}" id="influencer-rev-${element._id}" name="option" class="custom-control-input influencers" data-name="${element.influencerName}">
                             <label class="custom-control-label" for="influencer-rev-${element._id}">${element.influencerName}</label>
                             </label>
                           </div></li>`
          listInfluencers.append(option);
        });
      }
      selectDropdown('influencerssocial','select');
      activeOption('influencerssocial');
      selectDropdown('influencers','select');
      activeOption('influencers');
      autoSearchReviews('friends');
    });
  }

  function selectDropdown(parent, valoption) {
    const $parent = $('.drop-down-' + parent);
    const $inputcheckbox =  $parent.find('input[type="checkbox"]');
    const $listoption =  $parent.find('input[name="option"]');
    const $unselect = $parent.find('input[name="select"]');
    const $select = $parent.find('input[name="select"]:checked');
    const $undeselect = $parent.find('input[name="de-select"]');
    const $deselect = $parent.find('input[name="de-select"]:checked');
    const $option = $parent.find('input[name="option"]:checked');

    if(valoption == 'select' && $select && $select.length) {
      $inputcheckbox.each(function() {
        if($(this).val() !== 'de-select') $(this).prop('checked', true);
        else $(this).prop('checked', false);
        $parent.find('.btn-drop-down').html('<label class="drop-title">All selected</label><label class="caret pull-right"></label>');
      })
    } else if( valoption == 'de-select' && $deselect && $deselect.length) {
      $inputcheckbox.each(function() {
        if($(this).val() === 'de-select') $(this).prop('checked', true);
        else $(this).prop('checked', false);
        $parent.find('.btn-drop-down').html('<label class="drop-title">None selected</label><label class="caret pull-right"></label>');
      })
    } else if(!$option.length) {
      $inputcheckbox.each(function() {
        if($(this).val() === 'de-select') $(this).prop('checked', true);
        else $(this).prop('checked', false);
        $parent.find('.btn-drop-down').html('<label class="drop-title">None selected</label><label class="caret pull-right"></label>');
      })
    } else if($option.length === 1) {
      $unselect.prop('checked', false);
      $undeselect.prop('checked', false);
      $parent.find('.btn-drop-down').html(`<label class="drop-title">${$($option).data('name')}</label><label class="caret pull-right"></label>`);
    } else if($option.length > 1 && $option.length < $listoption.length) {
      $unselect.prop('checked', false);
      $undeselect.prop('checked', false);
      $parent.find('.btn-drop-down').html('<label class="drop-title">Some selected</label><label class="caret pull-right"></label>');
    } else if($option.length == $listoption.length) {
      $inputcheckbox.each(function() {
        if($(this).val() !== 'de-select') $(this).prop('checked', true);
        else $(this).prop('checked', false);
        $parent.find('.btn-drop-down').html('<label class="drop-title">All selected</label><label class="caret pull-right"></label>');
      })
    }
  }

  function activeOption($prent) {
    $('.drop-down-' + $prent).find('input[type="checkbox"]').each(function() {
      if($(this).is(':checked')) $(this).parents('li.list-option').addClass('active');
      else $(this).parents('li.list-option').removeClass('active');
    })
  }

  $('.btn-drop-down').click(function(e) {
    const tabdrop = $(this).data('tab');
    e.stopPropagation();
    activeOption(tabdrop);
    let boxdrop = $(this).next(".box-drop-down");
    if(boxdrop.is(':hidden')) boxdrop.show();
    else boxdrop.hide();
  })


  

  $('#resultRatedItems').on('click', '.rating-page-link', function(e){
		e.stopPropagation();
    let page = $(this).data('page');
    if (page && currentpage > 1 && page == 'back') {
      currentpage = currentpage - 1;
    } else if (page && currentpage < totalpage && page == 'next') {
      currentpage = currentpage + 1;
    } else if (!isNaN(Number(page))) {
      currentpage = Number(page);
    } else{
      currentpage = currentpage;
    }
    scroll('#friend_influencer_Tt');
    reviewsPage(REVIEWS, currentpage)
    // queryRivewsMenuItem(currentpage);
  });


  var parents = $('#public-ratings-friends');
  var reviewdata=[];
//review for social media page
  var loginuserId='';
  function queryAllRivewsMenuItem(pageNumber){
    const savedLat = Cookies.get('posLat');
    const savedLon = Cookies.get('posLon');
    let enableSubmit = true;
		let awardSearch = [];
    $('span.text-danger').text('');
    $('#socialawardsBox .awardSelectRestaurant').each(function(index){
			var itemKey = $(this).attr('id');
			var itemValue = $(this).prop('selectedIndex');
			awardSearch.push({[itemKey]:itemValue});
			
		})

    const condition = {
      address: $('#all-reviews-location').val() ? $('#all-reviews-location').val().trim() : '',
      searchradius: $('#radius-search-for-socialMediaFeed').val() ? $('#radius-search-for-socialMediaFeed').val() : '' ,
      userId: $("#socialmyuser-id").val().trim(),
      minStars: $('.social-media-minstars').rateYo('rating'),
      sort: $('#all-sort-reviews').val(),
      menuTypePreferences: $("#socialmenuTypePreferences").val(),
      dietaryPreferences: $("#socialdietaryPreferences").val(),
      filter_menustarrating:$(".socialfilter-menustarrating").rateYo('rating'),
      filter_restaurantstarrating:$(".socialfilter-restaurantstarrating").rateYo('rating'),
      open_hours_datetimepicker:$("#socialopen_hours_datetimepickers").val(),
      searchinputmenuitem:$("#socialsearchinputmenuitem").val(),
      filter_price_range:$("#socialprice_asearch").val(),
      sweet:$("#socialsweet").val(),
      salty:$("#socialsalty").val(),
      savory:$("#socialsavory").val(),
      bitter:$("#socialbitter").val(),
      sour:$("#socialsour").val(),
      spicy:$("#socialspicy").val(),
      healthy:$("#socialhealthy").val(),
      presentation:$("#socialpresentation").val(),
      quantity:$("#socialquantity").val(),
      value_for_price:$("#socialvalue_for_price").val(),
      searchinputrestaurant:$("#socialsearchinputrestaurant").val(),
      filter_checkboxOrderType:$("#socialfilter_checkboxOrderType").val(),
      filter_discounts:$("#socialfilter_discounts").val(),
      checkboxNoInteractions:$("#checkboxNoInteractions").val(),
      checkboxNoDecisionMaker:$("#checkboxNoDecisionMaker").val(),
      checkboxNoDecisionMakerHours:$("#checkboxNoDecisionMakerHours").val(),
      nointeractions_sincedate_datetimepicker:$("#nointeractions_sincedate_datetimepicker").val(),
      venueSeating:$("#socialvenueSeating").val(),
      groupSeating:$("#socialgroupSeating").val(),
      tableSeating:$("#socialtableSeating").val(),
      ownerType :$("#socialownerType").val(),
      womanOwned:$("#socialwomanOwned").val(),
      manOwned:$("#socialmanOwned").val(),
      lunchSelect:$("#sociallunchSelect").val(),
      lunchSelect:$("#sociallunchSelect").val(),
      dinnerSelect:$("#socialdinnerSelect").val(),
      socialrestaurantFeatures:$("#socialrestaurantFeatures").val(),
      corkageFeeAmount:$("#socialcorkageFeeAmount").val(),
      corkageotherdays_all:$("#socialcorkageotherdays_all").val(),
      slider_noise:$("#socialslider_noise").val(),
      slider_service:$("#socialslider_service").val(),
      slider_ambience:$("#socialslider_ambience").val(),
      slider_cleanliness:$("#socialslider_cleanliness").val(),
      hasBathroom:$("#socialhasBathroom").val(),
      lat: savedLat,
      lng: savedLon,
      pageNumber: pageNumber,
      nPerPage: perPage,
      publicReview: true,
			awardSearch:awardSearch
    };
    let tbf = $('input[name= "tabsocialmediafriends"]');
    if(tbf.length && tbf.is(':checked')) {
      condition.friends = []
      parents.find('input[name="option"].friends').each(function() {
        let frendId = $.trim($(this).val());
        if($(this).is(':checked') && frendId) condition.friends.push(frendId);
      });
      saveUserSearch(condition, 'friends');
    }
    let tbi = $('input[name= "tabsocialmediainfluencers"]');
    if(tbi.length && tbi.is(':checked')) {
      condition.influencers = []
      parents.find('input[name="option"].influencers').each(function() {
        let influencerId = $.trim($(this).val());
        if($(this).is(':checked') && influencerId) condition.influencers.push(influencerId);
      });
      saveUserSearch(condition, 'influencers');
    }
		loginuserId=$("#socialmyuser-id").val().trim();
    if($('#socialmy-reviews').is(':checked')) condition.myreview = true;
    if(condition.minStars) {
      condition.minStars = Number(condition.minStars) > 5 ? 5 : Number(condition.minStars)
    }
    if(!enableSubmit) {
      return;
    }

    parents.find('#all-result-search-reviews').empty();
    $('#resultAllRatedItems > nav').empty();
    parents.find('#all-search-reviews-text').css('display', 'none');
    parents.find('#all-search-reviews-text').text('');
    parents.find('#all-search-review-loading').fadeIn();
    
    searchAllRivewsMenuItem(condition)
    .then(function(res){
      REVIEWS = res;
      reviewdata.push(res);
      reviewssocialPage(res, pageNumber);
    });
  };
  $('#resultAllRatedItems').on('click', '.rating-page-link', function(e){
    e.stopPropagation();
    let page = $(this).data('page');
    if (page && currentpage > 1 && page == 'back') {
      currentpage = currentpage - 1;
    } else if (page && currentpage < totalpage && page == 'next') {
      currentpage = currentpage + 1;
    } else if (!isNaN(Number(page))) {
      currentpage = Number(page);
    } else{
      currentpage = currentpage;
    }
    scroll('#friend_influencer_Tt');
    //reviewsPage(REVIEWS, currentpage)
    reviewssocialPage(REVIEWS, currentpage)
  });
 // for social midea 

  function reviewssocialPage (reviews, pageNumber) {
    parents.find('#all-search-review-loading').fadeOut('slow', function() {
      let str = '';
      let totalMenus = 1;
      var likebutton='';
      let pageStart = (pageNumber - 1) * perPage;
      let pageEnd = pageStart + perPage;
      const fbAppId = $("#fbAppId").val();
      if (reviews && reviews.data && reviews.data.length){
        const menus = reviews.data.slice(pageStart, pageEnd);
        totalMenus = reviews.data.length;
        parents.find('#all-search-reviews-text').css('display', 'block');
        parents.find('#all-search-reviews-text').text(`Found ${totalMenus} result(s)`);
        if(menus.length) {
          menus.forEach(function(item){
            if (item.rating) {
              const review = item.rating;
              let ratingImage = '/images/tempfoodnotext.png';
              if (review.imageUrls) {
                ratingImage = review.imageUrls[0] ? review.imageUrls[0].url || review.imageUrls[0] : '/images/tempfoodnotext.png';
              }
              
              if(review.likeByUserIds && review.likeByUserIds.length>0){
                review.likeByUserIds.filter(function(item){
                  if(item==loginuserId){
                    likebutton= `<span id="dislike_${review._id}"><i class="far fa-heart green" onclick="likeByUserId('${review._id}','${loginuserId}','${review.menuItemId}','dislike')"></i></span>`
                    return likebutton;
                  }
                });
              }else{
                 likebutton=`<span id="like_${review._id}"><i class="far fa-heart red" onclick="likeByUserId('${review._id}','${loginuserId}','${review.menuItemId}','like')"></i></span>`
              }
              var heartcount=0
              if(review.likeByUserIds){
               heartcount= review.likeByUserIds.length;
              }

              const shareLink = `${window.location.origin}/restaurant/${review.venueId}?sharing=${review.menuItemId}`;
              var socialmideadiv='';
              if(window.navigator && window.navigator.userAgent){
                var userAgent = window.navigator.userAgent;
                if(userAgent == "PlateRateForIOS" || userAgent == 'PlateRateForAndroid'){
                  socialmideadiv = socialmideadiv + `<a class="btn btn-xs insta-share-button pull-right"
                  data-href="${shareLink}"
                  data-text="Check this out on PlateRate ! #platerate"
                  style="margin-top : 0px !important; margin-left : 5px;font-size:12px;"
                  href="javascript:void(0)">
                  <i class="fa fa-lg fa-instagram"></i>
                  Share
                </a>`;
                }
                if(userAgent == "PlateRateForIOS" || userAgent == 'PlateRateForAndroid'){
                  socialmideadiv = socialmideadiv + `<div class="pull-right pinterest-share-button-mobile">
                          <a href="javascript:void(0);" class="btn-pinterest-share-button-mobile"><span class="pinterest-mob-share-btn">Save</span></a>
                        </div>`;
                } else {
                  socialmideadiv = socialmideadiv + `<div class="pull-right pinterest-share-button-web">
                          <a data-pin-do="buttonBookmark" data-pin-tall="true" href="https://www.pinterest.com/pin/create/button/"></a>
                        </div>`;
                }
              }
              let starRating = review.starRating && review.starRating>0? review.starRating : 0 ;
              let avgRating = item.avgRating && item.avgRating!=undefined? item.avgRating : 0;
              let starRatingIcon = loadRate(starRating)
              let avgRatingIcon = loadRate(avgRating)
              
                socialmideadiv = socialmideadiv + `</div>`;
              str = str + `<hr/><div class="col-xs-12 col-md-4">
              <div class="card mb-3">
                <div class="mb-3 pt-3" style="min-height: 250px;background: #f6f6f6;">
                 <img src="${ratingImage}" class="img-responsive rating-img"/>
                </div>
                <div class="pl-3 pr-3">
                <div class="item-details">
                    <h4 class="title mb-2">
                        <a href="/restaurant/${item.venueSlug? item.venueSlug : review.venueId}?menuItem=${review.menuItemSlug ? review.menuItemSlug : review.menuItemId}" class="green item-name item-title">
                            ${review.menuItemName}
                        </a>
                        <span class="price-title pull-right">${(review.price && !isNaN(review.price)) ? "$" + review.price : '' }</span>
                    </h4>
                </div>
				<div class="row">
				<div class="col-md-12 text-right">
                   <span class="grey">by </span>
                    <a class="green rated-venue-name" href="/Publicprofile/${review.userId}">${review.userName}</a>
                  </div>
				</div>
                <div class="row mb-2">
                  <div class="col-md-12" style="display: inline-flex;"><span class="mr-2">Ratings:</span> 
                 ${starRatingIcon }
                  
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12" style="display: inline-flex;"><span class="mr-2">Avg Rating:</span> 
                   ${avgRatingIcon}
                  <span>(${item.totalCount}) Reviews</span></div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12 pr-1">
                      <i class="fas fa-heart grey-light"></i>
                      <span class="font-15">FlavorMatch:</span> <span class="font-15 green"><b>59%</b></span>
                  </div>
                </div>
                <div class="row center-flex-block mb-2">
                  <div class="col-md-12 pr-1">
                    <span class="grey">at </span>
                    <a class="green rated-venue-name" href="/restaurant/${item.venueSlug? item.venueSlug : review.venueId}">${review.venueName}</a>
                  </div>
                </div>
                <div class="row center-flex-block">
                  <div class="col-md-12">
                    <p class="text-left"><span class="grey">on</span>&nbsp;${review.dateUpdated ? new Date(review.dateUpdated).toString().slice(0, 15) : ''}</p>
                  </div>
                </div>
                <div class="row center-flex-block">
                  <div class="col-md-12">
                    <a class="view_rating_item" href="javascript:void(0);" data-item-id="${review._id}">View this rating</a>
                  </div>
                </div>
                <div class="row center-flex-block">
                  <div class="col-md-12">
                    <p style="max-width: 350px">${review.description || ''}</p>
                  </div>
                </div>
                <div class="text-right">
                  <p>
				  <span id="heart_${review._id}">${heartcount}</span>  Hearts, <span id="share_${review._id}">${review.shareRatingCounts ? review.shareRatingCounts :0} </span>Shares
				  </p>
                </div>
                
                <div class="row">
                    <div class="col-md-2">${likebutton}
					</div>
                    <div class="col-md-10">
                    <div onclick="shareByUserId('${review._id}','${loginuserId}','${review.menuItemId}')" id="social-share-${review.menuItemId}" class="social-share-container" style="margin-bottom: 10px;">
                    <a class="btn btn-xs facebook-share-button pull-right"
                      data-href="${shareLink}"
                      style="background-color:#365899;color:white;margin-top : 0px !important;margin-left : 5px;font-size: 15px;padding: 8px 10px;"
                      href="javascript:void(0)">
                      <input type="hidden" class="facebookShareLink" value="${shareLink}">
                      <input type="hidden" value="${fbAppId}" id="fbAppShareId">
                      <input type="hidden" value="${review.menuItemId}" id="menuItemId">
                      <i class="fa fa-sm fa-facebook"></i>
                      &nbspShare
                    </a>
                    <a class="btn btn-xs twitter-share-button pull-right cust_twitter_btn_font"
                      data-href="${shareLink}"
                      data-text="Check this out on PlateRate ! #platerate"
                      style="background-color:#1b95e0; color:white; margin-top : 0px !important; margin-left : 5px; padding: 8px 10px;"
                      href="javascript:void(0)">
                      <i class="fa fa-lg fa-twitter"></i>
                      Tweet
                    </a>
                    ${socialmideadiv}
                    </div>
                    </div>
                </div>
                <div class="text-center mt-3 venue-distance">
                    <label><span class="green">${item.distance || 0} miles</span> away</label>
                </div>
                <br/>
              </div>
              </div>
              </div>`
            }
          })
        }
      } else if(reviews.message) {
        str = `<p class="text-danger text-center" id="text-search-reviews">${res.message}</p>`
      } else {
        parents.find('#all-search-reviews-text').text("No available item");
      }
      parents.find('#all-result-search-reviews').html(str);
      var id="resultAllRatedItems";
      setPagination(Number(pageNumber), Number(totalMenus),id);
      
      $('.view_rating_item').on('click', function(){
        let item_id = $(this).data('item-id')
        modal_render_item_rating(item_id);
      })
    });
  }

  function setPagination(currentPage, totalPage,id) {
   
    console.log("totalPage====",totalPage);
    let pagination = '';
    totalPage = Math.ceil(totalPage / perPage);
    totalpage = totalPage;
    console.log("totalpagetotalpage=====",totalpage);
    let numberStart = 1;
    let numberEnd = 5;
    if (currentPage >= 5 && (currentPage + 1) < totalPage) {
      numberStart = currentPage - 3;
      numberEnd = currentPage + 2
    } else if (totalPage > 5 && (currentPage + 1) >= totalPage) {
      numberStart = currentPage - 5;
      numberEnd = totalPage;
    } else {
      numberStart = 1;
      numberEnd = totalPage < 5 ? totalPage : 5;
    };
    const page = (number) => {
      console.log("number====",number);
      let pagehtml = '';
      for (var i = numberStart; i <= numberEnd; i++){
        let active= ''
        if (i == currentPage) active = ' active'
        pagehtml = pagehtml + `<li class="page-item ${active}"><a class="page-link rating-page-link" href="javascript:void(0);" data-page='${i}'>${i}</a></li>`;
      }
      return pagehtml;
    }
    if (totalPage <= 5) {
      pagination = `
        <ul class="pagination">${page(totalPage)}</ul>
      `
    } else {
      pagination = `
        <ul class="pagination">
          <li class="page-item ${currentPage == 1 ? 'disabled' : ''}">
              <a class="page-link rating-page-link" data-page='1' href="javascript:void(0);">First</a>
          </li>
          <li class = "page-item ${currentPage == 1 ? 'disabled' : ''}" >
              <a class="page-link rating-page-link" data-page='back' href="javascript:void(0);">Back</a>
          </li>
          ${page(totalPage)}
          ${currentPage != totalPage ?
          `
          <li class="page-item">
              <a class="page-link" id="rating-fast-forward" href="javascript:void(0);">...</a>
          </li>
          `
          : ''}
          <li class="page-item page-item ${currentPage == totalPage ? 'disabled' : ''}">
              <a class="page-link rating-page-link" data-page='next' href="javascript:void(0);">Next</a>
          </li>
          <li class="page-item ${currentPage == totalPage ? 'disabled' : ''}">
              <a class="page-link rating-page-link" data-page='${totalPage}' href="javascript:void(0);">Last</a>
          </li>
        </ul>
      `
    }
    if (totalPage > 1){
      
      $('#'+id+' > nav').html(pagination);
    }
  }
  function likeByUserId(id,userId,menuId,flag) {
     var data = {
          userId: userId,
          menuId:menuId,
          id:id,
          flag:flag
      }
      $.ajax({
          url: '/ratings/followRating',
          method: 'POST',
          data: data,
      }).then(function(resultdata){
          toastr.success(resultdata.message || `Saved !`);
          if(flag!='follow'){
            if(flag=='like'){
              
             
              $("#like_"+id).empty();
              $("#like_"+id).html( `<i class='far fa-heart green' onclick="likeByUserId('${id}','${userId}','${menuId}','dislike')"></i>`);
                    
            }else if(flag=='dislike'){
              
               $("#dislike_"+id).empty();
              $("#dislike_"+id).html(`<i class='far fa-heart green' onclick="likeByUserId('${id}','${userId}','${menuId}','like')"></i>`);
             }
            $("#heart_"+id).text(resultdata.data.length )
          }
      })
  }

  function commentByUserId(frm){
    event.preventDefault();
    const data = $(frm).serializeArray();
    const dataval={};
    data.forEach(function(field,i){
      dataval[field.name] = field.value;
    });
    $.ajax({
        url: '/ratings/commentByUser',
        method: 'POST',
        data: dataval
    }).then(function(resultdata){
        $("#commentcount_"+resultdata.ratingId).text(resultdata.length)
        toastr.success(resultdata.message || `comment Saved`);
        getcomment(resultdata.ratingId)
    })
  }
  function replyByUserId(frm){
    event.preventDefault();
    const data = $(frm).serializeArray();
    const dataval={};
    data.forEach(function(field,i){
      console.log("field====",field);
      dataval[field.name] = field.value;
    });
    $.ajax({
        url: '/ratings/replyByUser',
        method: 'POST',
        data: dataval
    }).then(function(resultdata){
        toastr.success(resultdata.message || `comment Saved`);
        if(dataval.id){
          getcomment(dataval.id)
        }
        
    })
  }
  //sub reply funcation
  var reply='';
  function replyChildfun (data) {
    if(data.length>0) {
      data.forEach(function(item){
      replyChildfun(item?item:'');
      reply+=`<div class="row accord-grey centerBlock ml-4" role="tab" id="headingSocialComments3">
                  <h4 class="panel-title">
                      <a class="collapsed" data-toggle="collapse" data-parent="#collapseMyPlaterate" href="#collapseSocialSubreplyComments_${item._id}" aria-expanded="false"
                         aria-controls="collapseTt">
                          <span class = "plus_toggleable fa fa-plus"  ></span>
                              ${item ? item.message :''}
                      </a>
                  </h4>
                </div>
                <div id="collapseSocialSubreplyComments_${item._id}" class="panel-collapse collapse material_accordion_collapse" role="tabpanel"
                     aria-labelledby="headingReviews">
                     <form onSubmit="return replyByUserId(this);" id="form" class="mt-2 ml-4 mr-6">

                      <textarea cols="50" class="form-control textarea" id="reply_${item._id}" name="reply" rows="2"></textarea>
                      <input type="hidden" value="${item._id}" name="id" >
                      <input type="hidden" value="${item._id}" name="replyId" >
                      <input type="hidden" value="${item._id}" name="commentId"  >
                      
                      <input type="hidden" value="${loginuserId}" name="userId"  >
                       <input type="submit" value="Reply" class="btn btn-default btn-mid mt-2"  >
                    </form>   
                </div>`;
       })
    }
    return reply;
  }
  //get all comments 
  function getcomment(id) {
      $.ajax({
          url: '/ratings/getComments/'+id,
          method: 'GET',
         
      }).then(function(resultdata){
        var commentdata='';
        $("#commentcount_"+id).text(resultdata.length)
        if(resultdata.length>0){
          commentdata =`<div class="row accord-green centerBlock" role="tab" id="headingSocialComments">
                            <h4 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#collapseMyPlaterate" href="#collapseComments" aria-expanded="false"
                                   aria-controls="collapseTt">
                                    <span class = "plus_toggleable fa fa-plus"  ></span>
                                        Comments
                                </a>
                            </h4>
                        </div>
                       `;
           resultdata.forEach(function(item){
            var reply='';
            if( item.comment.length>0 && item.comment[0].reply.length>0){
              replyChildfun(item.comment[0].reply);
              reply+=`<div class="row accord-grey centerBlock ml-4" role="tab" id="headingSocialComments2">
                    <h4 class="panel-title">
                        <a class="collapsed" data-toggle="collapse" data-parent="#collapseMyPlaterate" href="#collapseSocialSubComments_${item.comment[0].reply[0]._id}" aria-expanded="false"
                           aria-controls="collapseTt">
                            <span class = "plus_toggleable fa fa-plus"  ></span>
                                ${item.comment[0].reply[0] ? item.comment[0].reply[0].message :''}
                        </a>
                    </h4>
                  </div>
                  <div id="collapseSocialSubComments_${item.comment[0].reply[0]._id}" class="panel-collapse collapse material_accordion_collapse" role="tabpanel"
                       aria-labelledby="headingReviews">
                       <form onSubmit="return replyByUserId(this);" id="form" class="mt-2 ml-4 mr-6">

                        <textarea cols="50" class="form-control textarea" id="reply_${item.comment[0].reply[0]._id}" name="reply" rows="2"></textarea>
                        <input type="hidden" value="${item._id}" name="id" >
                        <input type="hidden" value="${item.comment[0].reply[0]._id}" name="replyId" >
                        <input type="hidden" value="${item.comment[0]._id}" name="commentId"  >
                        
                        <input type="hidden" value="${loginuserId}" name="userId"  >
                         <input type="submit" value="Reply" class="btn btn-default btn-mid mt-2"  >
                      </form>   
                  </div>`;
                 
              }
            commentdata+=`
             <div id="collapseComments" class="panel-collapse collapse material_accordion_collapse" role="tabpanel"
                               aria-labelledby="headingReviews">
                <div class="row accord-grey centerBlock" role="tab" id="headingSocialComments">
                  <h4 class="panel-title">
                      <a class="collapsed" data-toggle="collapse" data-parent="#collapseMyPlaterate" href="#collapseSocialSubComments_${item._id}" aria-expanded="false"
                         aria-controls="collapseTt">
                          <span class = "plus_toggleable fa fa-plus"  ></span>
                              ${item.comment[0].message}
                      </a>
                  </h4>
                </div>
                <div id="collapseSocialSubComments_${item._id}" class="panel-collapse collapse material_accordion_collapse" role="tabpanel"
                   aria-labelledby="headingReviews">
                    <form onSubmit="return replyByUserId(this);" id="form" class="mt-2 ml-4 mr-6">
                      <textarea cols="50" class="form-control textarea" id="reply_${item._id}" name="reply" rows="2"></textarea>
                      
                      <input type="hidden" value="${item._id}" name="id" id="id_${item._id}" >
                      <input type="hidden" value="${loginuserId}" name="userId" id="userId_${item._id}" >
                     
                      <input type="submit" value="Reply" class="btn btn-default btn-mid mt-2" id="comment_${item._id}" >
                    </form>            
                      ${reply}
                  </div>
              </div>`
           });
           $( "#comments" ).empty();
           $( "#comments" ).append(commentdata );
        }
       
      })
  }
  function shareByUserId(id,userId,menuId) {
    var id=id;
      var data = {
          userId: userId,
          menuId:menuId,
          id:id
      }
      $.ajax({
          url: '/ratings/shareRatingCounts',
          method: 'POST',
          data: data,
      }).then(function(resultdata){
        console.log("resultdata==",resultdata.data);
        console.log("id====",id);
         $("#share_"+id).text(resultdata.data )
       })
  }
  //for price range change bubble value
  $("#socialprice_asearch").change(function(){
			var bla = $('#socialprice_asearch').val();
			let arr = bla.split(',');
			if(arr[0]==100){
			 // $("#lower").html(arr[0] );
				$(" #priceRange .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[0] + '</p>');
			}
			else{
				$("#priceRange .min-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[0] + '</p>');
			}
			if(arr[1]==100){
			 // $("#higher").html(arr[1]);
				$("#priceRange .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[1] + '</p>');
			}
			else{
				$("#priceRange .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[1] + '</p>');
			}
		});
  
  // call server side funaction for social  media feeds
  function searchAllRivewsMenuItem(condition){
      return $.ajax({
          url: '/rating/searchmenuitemallrating',
          method: 'POST',
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({searchParams: condition}),
          dataType: 'json',
      })
  }
  var currentpage = 1;
  var totalpage = 1;
  var perPage = 12;
  var savefriends = [];
  var saveinfluencers = [];
  var REVIEWS = {};
  function findReviews(clickedfrom){
    if (clickedfrom=="publicprofile"){
      parents = $('#public-ratings');
      queryRivewsMenuItem(1);
    } else if (clickedfrom=="friends"){
      parents = $('#public-ratings-friends');
      queryRivewsMenuItem(1);
    } else if(clickedfrom=="socialmedia"){
       parents = $('#social-ratings-friends');
       queryAllRivewsMenuItem(1);
    }
  }
//for the popup for social media feeds to show individual rating and comments etc
  $('body').append('<div class="modal-order-component"></div>')
  function modal_render_item_rating(item_key){
    // let item_node = $('#tree').fancytree('getTree').getNodeByKey(item_key);
    // ordered_item_key = item_node.key;
    getcomment(item_key);
    var reviewvalData=null;
    var itemdata=null;
    if(reviewdata[0].data){
      var reviewval = reviewdata[0].data.filter(function (item){
        return item.rating._id==item_key;
      })
      itemdata=reviewval[0];
      reviewvalData=reviewval[0].rating;
    }
    var heartcount=0
    if(reviewvalData.likeByUserIds){
     heartcount= reviewvalData.likeByUserIds.length;
    }
    var influencerdiv = '';
    if(reviewvalData.isFollowing == true){
      influencerdiv = `
                                <button type="button"  onclick="followInfluencer(event,'${reviewvalData.userId}')" id="followIfnfluencerBtn" class="btn btn-success" style="display:none;">Follow</button>
														<button type="button" id="unfollowInfluencerBtn" onclick="unfollowInfluencer(event,'${reviewvalData.userId}')" class="btn btn-success" >UnFollow</button>
    `;
    }
    else if(reviewvalData.isFollowing == false){
    influencerdiv = `
                                <button type="button"  onclick="followInfluencer(event,'${reviewvalData.userId}')" id="followIfnfluencerBtn" class="btn btn-success">Follow</button>
														<button type="button" id="unfollowInfluencerBtn" onclick="unfollowInfluencer(event,'${reviewvalData.userId}')" class="btn btn-success" style="display:none;">UnFollow</button>
    `;
    }
    let starRating = reviewvalData.starRating && reviewvalData.starRating>0? reviewvalData.starRating : 0 ;
    let avgRating = itemdata.avgRating && itemdata.avgRating!=undefined? itemdata.avgRating : 0;
    let starRatingIcon = loadRate(starRating);
    let avgRatingIcon = loadRate(avgRating);
    
    let html = `<div id="modal-order-item-addons" class="modal fade" role="dialog">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="padding: 20px;">
                      <div class="modal-header">
                         <button type="text"  class="close closemodel"  style="background-color: transparent;">
                            <i class="fa fa-times fa-2x" aria-hidden="true" style="color: red"></i>
                         </button>
                        <h2 class="modal-title selected-item-name" data-item-key="gffgf">${reviewvalData.menuItemName}</h2>
                      </div>
                      <div class="modal-body">
                        <div class="col-xs-12 col-md-12">
                          <div class="col-md-2 mb-1">${influencerdiv}
                          </div>
                          <div class="card pt-3 mb-3">
                          
                            <div >
                              <img src="${reviewvalData.imageUrls && reviewvalData.imageUrls.length>0 ? reviewvalData.imageUrls[0].url : '/images/tempfoodnotext.png' }" class="img-responsive rating-img"/>
                            </div>
                            <div class="pl-3 pr-3">
                            <div class="item-details">
                                <h4 class="title">
                                    <a href="/restaurant/${reviewvalData.venueSlug? reviewvalData.venueSlug : reviewvalData.venueId}?menuItem=${reviewvalData.menuItemSlug ? reviewvalData.menuItemSlug : reviewvalData.menuItemId}" class="green item-name item-title">
                                        ${reviewvalData.menuItemName}
                                    </a>
                                    <span class="price-title">${reviewvalData.price} </span>
                                </h4>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-6"><span class="mr-2">Ratings:</span> 
                              ${starRatingIcon}

                              </div>
                              <div class="col-md-6">
                               <span class="grey">by  ${reviewvalData.userName}</span>
                                <a class="green rated-venue-name" href=""</a>
                              </div>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-12"><span class="mr-2">Avg Ratings:</span> 
                              ${avgRatingIcon}
                              <span>(${itemdata.totalCount}) Reviews</span></div>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-12 pr-1">
                                  <i class="fas fa-heart grey-light"></i>
                                  <span class="font-15">FlavorMatch:</span> <span class="font-15 green"><b>59%</b></span>
                              </div>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-12 pr-1">
                                <span class="grey">at </span>
                                <a class="green rated-venue-name" href="">${reviewvalData.venueName}</a>
                              </div>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-12">
                                <p><span class="grey">on</span>&nbsp;${reviewvalData.dateUpdated ? new Date(reviewvalData.dateUpdated).toString().slice(0, 15) : ''}</p>
                              </div>
                            </div>
                            <div class="row center-flex-block">
                              <div class="col-md-12">
                                <p style="max-width: 350px">${reviewvalData.review}</p>
                              </div>
                            </div>
                            <div class="center-flex-block">
                              <p style="max-width: 350px"><span id="heart_${reviewvalData._id}" >${heartcount} Hearts</span>,<span id="commentcount_${reviewvalData._id}""> </span>Comments,<span id="share_${reviewvalData._id}">${reviewvalData.shareRatingCounts ? reviewvalData.shareRatingCounts:0 }</span> Shares</p>
                            </div>
                            <div class="center-flex-block venue-distance">
                                <label><span class="green">${reviewvalData.distance || 0} miles</span> away</label>
                            </div>
                            <div class="center-flex-block">
                              <form onSubmit="return commentByUserId(this);" id="form">
                                <textarea cols="50" class="form-control textarea" id="message_${reviewvalData._id}" name="message" rows="2"   ></textarea>
                                <input type="hidden" value="${reviewvalData._id}" name="id" id="id_${reviewvalData._id}" >
                                <input type="hidden" value="${loginuserId}" name="userId" id="userId_${reviewvalData._id}" >
                                <input type="hidden" value="${reviewvalData.menuItemId}" name="menuId" id="menuId_${reviewvalData._id}" >
                                
                                <input type="submit" value="Comment" class="btn btn-default btn-mid mt-2" id="comment_${reviewvalData._id}" >
                              </form>
                            </div>
                            <div id="comments"></div>
								            
                            <br/>
                          </div>
                        </div>
                      </div>
                     </div>
                    </div>
                  </div>
                </div>`
       ;
       $('.float-action-buttons').css({'display': 'none'});
        $('.modal-order-component').html(html);

        $(".closemodel").click(function(e) {
          $("#modal-order-item-addons").modal('hide');
          $('.float-action-buttons').css({'display': 'block'});

          e.stopPropagation();         // Dropdown will not hide when select checkbox..
        });
      return $("#modal-order-item-addons").modal()
  }
  //Phone and email validations 
  function validateEmail(field) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
    return (regex.test(field)) ? true : false;
  }
  function validatePhone(str) {
    return /[\+]?[\s]?(\d(\-|\s)?)?(\(\d{3}\)\s?|\d{3}\-?)\d{3}(-|\s-\s)?\d{4}(\s(ex|ext)\s?\d+)?/.test(str);
  }
  
  //Add as friend from Public profile
  function publicAddAsFriend(email, phone){
    let _user_id = $("#userId").val();
    console.log("_user_id in publicprofile====", _user_id)
    console.log("window.location.pathname=======", window.location.pathname)
    if( _user_id != undefined  &&  _user_id !='') {
    if(email){
      if(validateEmail(email)){
          var e = {
                emails: $.trim(email).toLowerCase(),
            };
            $.ajax({
                url: "/userfriends/invitebyemail",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(e),
                success: function (e) {
                        if (e.status == "Successful"){
                          Swal.fire("Success", "Invite sent successfully to people who are not already your friends.", "success");
                        } else {
                          Swal.fire("Oops", e.message, "error");
                        }
                },
                error: function (e) {
                  Swal.fire("Oops", "Please try again and reload the page once!", "error");
                }
            })
        }
    }else{
        if(validatePhone(phone)){
            var e = {
                phones: $.trim(phone),
            };
            $.ajax({
                url: "/userfriends/invitebyphone/",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(e),
                success: function (e) {
                        if (e.status == "Successful"){
                          Swal.fire("Success", "Invite sent successfully to people who are not already your friends.", "success");
                        } else {
                          Swal.fire("Oops", e.message, "error");
                        }
                },
                error: function (e) {
                  Swal.fire("Oops", "Please try again and reload the page once!", "error");
                }
            })
        }
    }
  }
    else {
      window.location.assign('/users/register?next=' + location.pathname)
    }
}
